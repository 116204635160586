import { useDetailedPricingRfqSubheaderText } from '@components/detailed-pricing/hooks/use-detailed-pricing-rfq-subheader-text';
import { useVenuePricingRfqSubheaderText } from '@components/pricing/hooks/use-venue-pricing-rfq-subheader-text';

export const useInlineRfqSubheaderTextOverride = (headerText?: string) => {
	const detailedPricingSubheaderText =
		useDetailedPricingRfqSubheaderText(headerText);
	const venuePricingSubheaderText = useVenuePricingRfqSubheaderText();

	return venuePricingSubheaderText || detailedPricingSubheaderText || undefined;
};
