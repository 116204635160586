import { LoadConfigByStorefront } from '../../api/config';
import LocalStorage from './local_storage';
import {
	flattenConfig,
	loadLocalStorage,
	loadMember,
	localStorageCommonProps,
} from './utils/general';
import { loadLastRequest } from './utils/lastRequest';

class DataLoader {
	constructor({
		category,
		member,
		lastRequest,
		storefrontRaw,
		formType,
		wedding,
		booking,
	}) {
		this._category = category;
		this._config = LoadConfigByStorefront(storefrontRaw, formType);
		const flatSchema = flattenConfig(this.schema);

		this._properties = flatSchema.properties;
		this._ui = flatSchema.ui;

		this._member = loadMember({
			member,
			wedding,
			booking,
			guest_count: this.properties.guest_count,
		});
		this._localStorage = loadLocalStorage(
			category,
			this.properties,
			LocalStorage.get() || {},
			this._ui,
		);
		this._lastRequest = loadLastRequest(
			lastRequest,
			category,
			this.properties,
			this._ui,
		);
		this._data = this._mergeData();
		this._isValid = false;
		this._formData = this._cleanDataVsSchema();
	}

	get schema() {
		return this._config;
	}

	get properties() {
		return this._properties;
	}

	get requiredProperties() {
		const properties = {};
		// biome-ignore lint/complexity/noForEach: <explanation>
		Object.keys(this._properties).forEach((key) => {
			const prop = this._properties[key];
			if ('required' in prop && prop.required) {
				properties[key] = prop;
			}
		});
		return properties;
	}

	get ui() {
		return this._ui;
	}

	get member() {
		return this._member;
	}

	get localStorage() {
		return this._localStorage;
	}

	get lastRequest() {
		return this._lastRequest;
	}

	get data() {
		return this._data;
	}

	get formData() {
		return this._formData;
	}

	get isValid() {
		return this._isValid;
	}

	_mergeData() {
		return Object.assign(this.member, this.localStorage, this.lastRequest);
	}

	_cleanDataVsSchema() {
		const data = { ...this.data };
		const schemaKeys = Object.keys(this.properties);
		const requiredSchemaKeys = Object.keys(this.requiredProperties);
		const dataKeys = Object.keys(data);

		// find data keys not in schema keys
		const keysToDelete = dataKeys.filter(
			(dataKey) =>
				!schemaKeys.find(
					(schemaKey) =>
						schemaKey === dataKey || dataKey === 'preferredContactMethod',
				),
		);

		// delete props not in config
		// biome-ignore lint/complexity/noForEach: <explanation>
		keysToDelete.forEach((key) => {
			delete data[key];
		});

		const formKeys = Object.keys(data);

		// required properties need to be present
		const requiredMatchingKeys = requiredSchemaKeys.filter((reqKey) =>
			formKeys.find((key) => reqKey === key),
		);

		this._isValid = requiredSchemaKeys.length === requiredMatchingKeys.length;

		return data;
	}

	formDataToLocalStorage(formData) {
		const current = LocalStorage.get() || {};
		const commonProps = {};
		// deep clone formData
		const data = JSON.parse(JSON.stringify(formData));

		// common props
		// biome-ignore lint/complexity/noForEach: <explanation>
		localStorageCommonProps().forEach((key) => {
			if (key in data) {
				// move common props to it's own obj
				commonProps[key] = data[key];
				// delete commonProps from data
				delete data[key];
			}
		});

		// set category data if any
		const categoryData = {};
		if (Object.keys(data).length) {
			categoryData[this._category] = data;
		}

		const output = Object.assign(current, categoryData, commonProps);
		LocalStorage.set(output);
	}
}

export default DataLoader;
