import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import type { CategoryCode } from '@typings/Category';
import type { Member } from '@typings/membership';
import type { InlineRfqSourceContent } from '@typings/sourceContent';
import { constructAnalyticsProperties } from '@utils/constructAnalyticsProperties';
import { transformVendorTier } from '@utils/transformVendorTier';
import { useCallback } from 'react';
import type { Affiliate, Decorated, Raw } from 'types/vendor';
import DataLoader from '../../data/loader/DataLoader';
import Hoek from '../../utils/hoek';

type ActionType = 'edit' | 'close' | 'initial' | 'submit';

type RFQEvent = {
	event: 'RFQ Flow Interaction';
	properties: Record<string, string> & {
		selection: ActionType;
	};
	defaults: Record<string, string | null>;
};

type Source = {
	member: Member;
	storefront: Raw & {
		_bow?: string | undefined;
		_categoryCode?: 0 | CategoryCode | undefined;
		_price?: string | undefined;
		_transactional?: boolean;
		_phoneNumber?: string | undefined;
	};
	isTestStorefront: boolean | undefined;
	leadFormType: string;
};

// TODO: The original tracking in WizardTracking.js and Wizard.js
// relied on js objects to build the event properties on runtime
// Due to that we need to add anys in order to be able to manage the types correctly, to solve this
// this user story was created https://theknotww.atlassian.net/browse/TKMPTN-434

const purchaseMapping = {
	FREEMIUM: 'unpaid storefront',
	UNPAID: 'unpaid storefront',
	PAID: 'paid storefront',
};

const getDefaults = (vendor: Decorated, onStorefront: boolean) => ({
	...constructAnalyticsProperties({}),
	sourcePage: onStorefront
		? purchaseMapping[vendor.purchaseStatus]
		: 'category results',
});

const storefrontMapping = {
	mediaSummaryCount: 'storefront.mediaSummary.total',
	partnerAccountId: 'storefront.accountId',
	partnerAccountStatus: 'storefront.accountStatus',
	partnerVendorId: 'storefront.vendorId',
	photoSummaryCount: 'storefront.photoSummary.total',
	vendorAddress: 'storefront.location.address.address1',
	vendorBestOfWeddings: 'storefront._bow',
	vendorCategoryCode: 'storefront._categoryCode',
	vendorCity: 'storefront.location.address.city',
	vendorClaimedStatus: 'storefront.claimedStatus',
	vendorDisplayId: 'storefront.displayId',
	vendorId: 'storefront.id',
	vendorMarketCode: 'storefront.marketCode',
	vendorName: 'storefront.name',
	vendorPriceTier: 'storefront._price',
	vendorPurchaseStatus: 'storefront.purchaseStatus',
	vendorReviewCount: 'storefront.reviewSummary.count',
	vendorState: 'storefront.location.address.state',
	vendorTransactions: 'storefront._transactional',
	vendorUrl: 'storefront.websiteUrl',
	vendorVendorTier: 'storefront.vendorTier',
	vendorzip: 'storefront.location.address.postalCode',
};

const dataMapping = {
	rfqAboutWedding: 'data.body',
	rfqAdditionalEquipment: 'data._additionalServices',
	rfqBudget: 'data.budget.display',
	rfqEmail: 'data.emailAddress',
	rfqEvents: 'data._events',
	rfqFirstName: 'data.firstName',
	rfqFlexibleWeddingDate: 'data._flexibleWeddingDate',
	rfqGuestCount: 'data.guest_count.display',
	rfqLastName: 'data.lastName',
	rfqWeddingDate: 'data.xo_date_picker.weddingDate',
	rfqWhoNeedsServices: 'data._whoNeedsServices',
	test: 'isTestStorefront',
	savedRFQ: 'isSaved',
};

const initData = (props: {
	category: CategoryCode;
	member: Member;
	storefrontRaw: Raw;
	lastRequest?: unknown;
	wedding?: unknown;
	booking?: unknown;
}) => {
	return new DataLoader({
		category: props.category,
		member: props.member,
		lastRequest: props.lastRequest,
		storefrontRaw: props.storefrontRaw,
		formType: 'RFQ',
		wedding: props.wedding,
		booking: props.booking,
	});
};

const _transformAndClean = (
	source: Source,
	mappings: Record<string, string>,
) => {
	const transformed = Hoek.transform(source, mappings, null);
	// remove undefined properties
	const cleaned = Hoek.merge({}, transformed, false, true);
	return cleaned;
};

const parseProperties = (
	data: DataLoader,
	sourceContent: InlineRfqSourceContent,
	sourcePage: Page.PageType,
	source: Source,
	event: RFQEvent,
	referred: boolean,
) => {
	const input = {
		data: transformData(data.formData),
		...source,
	};
	const properties = _transformAndClean(input, event.properties);
	properties.vendorVendorTier = transformVendorTier(
		properties.vendorVendorTier,
	);
	properties.vendorReferredMarketCode = referred;
	properties.sourceContent = sourceContent;
	properties.sourcePage =
		sourcePage === 'city' ? 'category results' : sourcePage;

	return Object.assign(
		{ ...event.defaults, selection: event.properties.selection },
		properties,
	);
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const transformData = (input: any) => {
	const output: {
		[key: string]: unknown;
	} = { ...input };

	output._flexibleWeddingDate =
		input && 'flexibleWeddingDate' in input
			? input.flexibleWeddingDate
				? 'Yes'
				: 'No'
			: undefined;

	output.additionalVendor = false;

	output.bluecard = input ? !!input.bluecard : undefined;

	return output;
};

// special storefront props
const transformStorefront = (input: Raw) => {
	const output: Raw & {
		_bow?: string;
		_categoryCode?: CategoryCode | 0;
		_price?: string;
		_transactional?: boolean | null;
		_phoneNumber?: string;
	} = { ...input };

	const bow = input?.affiliates?.find(
		(a: Affiliate) => a.id === 'c59bdf9a-6a9e-423c-8332-3c3aca6df62b',
	);

	output._bow = bow?.affiliates
		?.map((b) => {
			if (b?.definition?.match(/\d{4}/)) {
				return b.definition;
			}
			if (b?.definition?.match(/Hall of Fame/)) {
				return 'HOF';
			}
		})
		.filter((b) => b !== undefined)
		.join(',');

	const catCodes = input?.categories?.map((c) => c.code);
	output._categoryCode = catCodes?.length && catCodes[0];

	const price = input?.facets?.find(
		(f) => f.id === 'c6046f9a-5780-4b76-8ace-731ff1b7ae62',
	);
	output._price = price?.facets?.[0].name;

	output._transactional =
		input?.addOns && !!input?.addOns.find((a) => a.code === 'TX');

	const phone = input?.phones?.find((ph) => ph.type === 'PRIMARY');
	output._phoneNumber = phone?.number;

	return output;
};

const rfqFlowInteraction = (
	selection: ActionType,
	vendor: Decorated,
	onStorefront: boolean,
): RFQEvent => ({
	event: 'RFQ Flow Interaction',
	properties: {
		...dataMapping,
		...storefrontMapping,
		selection,
	},
	defaults: {
		...getDefaults(vendor, onStorefront),
	},
});

const composeEvent = (
	rawVendor: Raw,
	vendor: Decorated,
	member: Member,
	isReferred: boolean,
	initiator: InlineRfqSourceContent,
	sourcePage: Page.PageType,
	event: RFQEvent,
) => {
	const storefront = transformStorefront(rawVendor) as Raw & {
		_bow?: string;
		_categoryCode?: CategoryCode | 0;
		_price?: string;
		_transactional?: boolean | undefined;
		_phoneNumber?: string;
	};

	const data = initData({
		category: vendor?.categoryCode,
		storefrontRaw: rawVendor,
		member,
	});

	const referred = isReferred;
	const sourceContent = initiator;
	const source = {
		member: member,
		storefront: storefront,
		isTestStorefront: rawVendor.isTestData ? true : undefined,
		leadFormType: 'direct',
	};

	const parsedProperties = parseProperties(
		data,
		sourceContent,
		sourcePage,
		source,
		event,
		referred,
	);

	event.properties = {
		...event.properties,
		...parsedProperties,
	};

	return event;
};

const checkIfShouldTrack = (
	vendor: Decorated,
	rawVendor: Raw,
	member: Member,
): boolean => {
	return !!(vendor && rawVendor);
};

export const useTrackInlineForm = (
	action: ActionType,
	initiator: InlineRfqSourceContent,
) => {
	const { track } = useAnalyticsContext();
	const stateVendor = useAppSelector(
		(state) => state.vendor.vendor as Decorated,
	);
	const sourcePage = useAppSelector((state) => state.page.pageType);
	const isReferred = useAppSelector(
		(state) => state.settings.isReferred || false,
	);
	const eventMapping = rfqFlowInteraction(
		action,
		stateVendor,
		sourcePage === 'storefront',
	);
	const stateRawVendor = useAppSelector(
		(state) => state.vendor.vendorRaw as Raw,
	);
	const member = useAppSelector((state) => state.membership.member as Member);

	return useCallback(
		(vendor = stateVendor, rawVendor = stateRawVendor) => {
			const shouldTrack = checkIfShouldTrack(vendor, rawVendor, member);

			if (!shouldTrack) {
				return;
			}

			const event = composeEvent(
				rawVendor,
				vendor,
				member,
				isReferred,
				initiator,
				sourcePage,
				eventMapping,
			);
			track(event);
		},
		[
			track,
			stateRawVendor,
			stateVendor,
			member,
			isReferred,
			initiator,
			sourcePage,
			eventMapping,
		],
	);
};
